<template>
  <vx-card>
    <!-- ZOOM POP UP -->
    <template>
      <!-- ZOOM IMAGE POP UP -->
      <vs-popup
        classContent="popup-example label-font "
        title="Stock Image"
        :active.sync="zoomPopupActive"
      >
        <div style="width:400px; margin: 5px auto;">
          <img class="responsive rounded" :src="zoomzoom" style="width:95%;" />
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="ข้อมูลเพิ่มเติมในการคืนชุด"
        :active.sync="popupActive"
      >
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--------------------------------------------------------------------->

        <!-- BEGIN: RETURN DETAIL -->

        <!--------------------------------------------------------------------->
        <!-- BEGIN: RETURN DETAIL -->

        <div class="grid-layout-container alignment-block normal-font" style=" width:100%;">
          <!--  โน้ต/หมายเหตุ  -->
          <vs-row style="margin-top:15px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >โน้ต/หมายเหตุ</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="normal-font padding-left-30 px-2"
            >
              <pre style="font-family: mitr;">{{ remark }}</pre>
            </vs-col>
          </vs-row>
          <!--  หักเงินประกัน  -->
          <vs-row style="margin-top:15px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >หักเงินประกัน</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="normal-font padding-left-30 px-2"
            >
              {{ deduction }}
              <br />
              <span
                class="little-comment the-blue pl-2"
              >(วงเงินประกัน {{ formatPrice(bailTotal - bailDiscount) }} บาท)</span>
            </vs-col>
          </vs-row>

          <!--  ลงวันที่หักเงินประกัน  -->
          <!-- <vs-row style="margin-top:15px;" class="row-form" vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="4"
              vs-sm="4"
              vs-xs="4"
              class="form-label px-2"
            >ลงวันที่หักเงินประกัน</vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              class="normal-font padding-left-30 px-2"
            >{{formatDate(deductionDate)}}</vs-col>
          </vs-row>-->
        </div>

        <!-- BEGIN: RETURN DETAIL -->

        <div
          class="grid-layout-container alignment-block normal-font padding-left-30 px-2"
          style=" width:100%;"
        >
          <vs-row vs-w="12">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="7"
              vs-sm="7"
              vs-xs="7"
              class="px-2"
            >
              <vs-row vs-w="12">
                <!--  สรุปยอดเงินประกันที่ต้องโอนคืน  -->
                <vs-row style="margin-top:30px;" class="row-form" vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="form-label px-2 py-2 label-btn-red"
                  >สรุปยอดที่ต้องโอนคืน</vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="normal-font padding-left-30 px-2 py-2 label-btn-red"
                  >
                    {{
                    formatPrice(bailTotal - bailDiscount - deduction)
                    }}
                  </vs-col>
                </vs-row>

                <!--  ธนาคาร  -->
                <vs-row style=" margin-top:30px;  margin-bottom:10px;" vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="form-label px-2"
                  >ธนาคาร</vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="normal-font padding-left-30 px-2"
                  >{{ bankName }}</vs-col>
                </vs-row>

                <!--  ชื่อบัญชีลูกค้า  -->
                <vs-row style=" margin-top:10px; margin-bottom:10px;" vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="form-label px-2"
                  >ชื่อบัญชีลูกค้า</vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="normal-font padding-left-30 px-2"
                  >{{ accountName }}</vs-col>
                </vs-row>

                <!--  เลขที่บัญชีลูกค้า  -->
                <vs-row style=" margin-top:10px; margin-bottom:10px;" vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="form-label px-2"
                  >เลขที่บัญชีลูกค้า</vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="normal-font padding-left-30 px-2"
                  >{{ accountNumber }}</vs-col>
                </vs-row>

                <!--  เบอร์ติดต่อ  -->
                <vs-row style=" margin-top:10px; margin-bottom:60px;" vs-w="12">
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-end"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="form-label px-2"
                  >เบอร์ติดต่อ</vs-col>
                  <vs-col
                    vs-type="flex"
                    vs-justify="flex-start"
                    vs-align="center"
                    vs-lg="6"
                    vs-sm="6"
                    vs-xs="6"
                    class="normal-font padding-left-30 px-2"
                  >{{ transferContact }}</vs-col>
                </vs-row>
              </vs-row>
            </vs-col>
            <!-- UPDLOAD HERE -->
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="5"
              vs-sm="5"
              vs-xs="5"
            >
              <div
                class="grid-layout-container alignment-block"
                style="vertical-align:text-top ;width:100%;"
              >
                <vs-row v-if="$acl.check('admin')" vs-w="12">
                  <!-- Product Image -->
                  <template v-if="dataImg">
                    <!-- Image Container -->
                    <div class="img-container w-64 mx-auto flex items-center justify-center">
                      <img :src="dataImg" alt="img" class="responsive" />
                    </div>

                    <!-- Image upload Buttons -->
                    <div class="modify-img flex justify-between mt-5">
                      <input
                        type="file"
                        class="hidden"
                        ref="updateImgInput"
                        @change="updateCurrImg"
                        accept="image/*"
                      />

                    </div>
                  </template>

                  <div class="upload-img mt-5" v-if="!dataImg">
                    <input
                      type="file"
                      class="hidden"
                      ref="uploadImgInput"
                      @change="updateCurrImg"
                      accept="image/*"
                    />
                    <vs-button @click="$refs.uploadImgInput.click()">Upload Image</vs-button>
                  </div>
                </vs-row>
                <!-- /NEW UPLOAD IMAGE  -->

                <vs-row vs-w="12" style="margin-top:30px;">
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <vs-row vs-w="12">
                      <vs-col
                        v-for="(item, index) in this.slip"
                        :key="index"
                        vs-type="flex"
                        vs-justify="flex-start"
                        vs-align="flex-start"
                        vs-lg="6"
                        vs-sm="6"
                        vs-xs="12"
                        class="imgclass px-2"
                      >
                        <div vs-w="12">
                          <i
                            v-if="$acl.check('admin')"
                            @click="removeImage(item.slipImageId)"
                            style="float:right; font-size:22px; vertical-align:middle; color:red;  cursor:pointer;"
                            class="material-icons"
                          >delete_forever</i>
                          <img
                            @click="zoomimage(item.fileName)"
                            :src="showSlip(item.fileName)"
                            style="width: 100%; cursor:pointer;"
                          />
                        </div>

                        <!-- </div> -->
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-row>
              </div>
            </vs-col>

            <!--  UPLOAD FIELD  -->
            <vs-row style=" margin-top:10px; margin-bottom:40px;" vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="flex-end"
                vs-align="center"
                vs-lg="4"
                vs-sm="4"
                vs-xs="4"
                class="form-label px-2"
              >อัพโหลดสลิปการโอนคืน</vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="6"
                vs-sm="6"
                vs-xs="6"
                class="normal-font padding-left-30 px-2"
              >{{ transferContact }}</vs-col>
            </vs-row>
          </vs-row>
        </div>
        <!-- END: RETURN DETAIL -->

        <!-- END: RETURN DETAIL -->

        <!--------------------------------------------------------------------->
        <!-- </vs-table>END: RETURN DETAIL -->

        <!--------------------------------------------------------------------->
        <div style="border-top: 1px solid #E3E3E3; margin-bottom:10px;margin-top:20px; "></div>
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <!-- <vs-row vs-w="12" vs-type="flex" vs-justify="center">
          <vs-button
            @click="$router.back()"
            style="margin-right: 20px;"
            color="warning"
            type="filled"
          >กลับไปหน้าเช่า</vs-button>
          <vs-button color="success" type="filled">ยืนยันการเช่า</vs-button>
        </vs-row>-->
        <!--------------------------------------------------------------------->
        <vs-row vs-w="12" type="flex" vs-justify="flex-end">
          <!-- <vs-button @click="update()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button> -->
          <vs-button
            @click="closePanel()"
            style="margin:10px;"
            color="danger"
            type="filled"
          >เสร็จสิ้น</vs-button>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <template>
      <!-- POP UP -->
      <vs-popup classContent="popup-example" title="บันทึกข้อความ" :active.sync="notePopupActive">
        <!--  เปลี่ยนสถานะของรายการเช่าสินค้า  -->
        <!--  Add this to data part  (import vSelect from "vue-select")     "v-select": vSelect  -->
        <!--  :select >>> ...Selected /  :options >>> ...Options -->
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:30px;">
          <!-- <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="5"
            class="form-label px-2"
          >บันทึกข้อความ</vs-col> -->
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
          >
            <vs-textarea v-model="remark"></vs-textarea>
          </vs-col>
        </vs-row>

         <vs-row vs-type="flex"
            vs-justify="flex-end"
            vs-align="center" class="row-form" vs-w="12" style="margin-top:30px;margin-bottom:30px;">

            <vs-button
              @click="closeNotePanel()"
              style="margin:10px;"
              color="danger"
              type="border"
            >ยกเลิก</vs-button>

            <vs-button @click="updatenote()" style="margin:10px;  width:150px;" color="success" type="filled">ตกลง</vs-button>

        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <!-- BACK STATUS เปลี่ยนสถานะคืน -->
    <template>
      <!-- POP UP -->
      <vs-popup
        classContent="popup-example"
        title="เปลี่ยนสถานะคืน"
        :active.sync="backstatusPopupActive"
      >
        <vs-row class="row-form" vs-w="12" style="margin-top:20px;margin-bottom:30px;">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="10"
            vs-sm="10"
            vs-xs="10"
            class="form-label px-2"
          >
            เปลี่ยนสถานะ Order เลข
            <b>{{ this.orderNumber }}</b> เป็นรอรับชุด
          </vs-col>
        </vs-row>

        <vs-button @click="updateback()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
        <vs-button
          @click="closeBackStatusNotePanel()"
          style="margin:10px;"
          color="danger"
          type="filled"
        >ยกเลิก</vs-button>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- HEADER -->
    <!-- <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="6"
      >วันที่คืนชุด</vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="3"
        vs-sm="3"
        vs-xs="6"
        class="px-2"
      >
        <datepicker
          style="width:100%;"
          placeholder="วันที่คืนชุด"
          format="yyyy-MM-dd"
          v-model="searchDate"
        ></datepicker>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="12"
        class="px-2"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
          >
            <vs-button @click="searchBtn()" style="width:100%;" color="primary" type="filled">ค้นหา</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="resetBtn()" style="width:100%;" color="warning" type="filled">reset</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          ></vs-col>
        </vs-row>
      </vs-col>
    </vs-row> -->
    <!-- BRANCHES -->
    <!-- *** BEGIN : 2020-11-28 : Search By branches -->
<!--
    <vs-row vs-w="12">
      <ul v-if="permission === 'admin'" class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in allBranch"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>


      <ul v-else class="demo-alignment">
        <li
          @click="theSearch($event, index)"
          class="choose"
          v-for="(b, index) in getMyBranches"
          :key="index"
        >{{ b.branchName }}</li>
      </ul>
    </vs-row> -->

    <!-- *** END : 2020-11-28 : Search By branches -->
    <!-- BRANCHES -->

      <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="6"
      >วันคืนชุด</vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="3" vs-xs="6">
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;"
        >
          <vs-row>
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <v-date-picker
                style="width:100%;font-size:14px;"
                :locale="{
                  id: 'th',
                  firstDayOfWeek: 2,
                  masks: { L: 'DD/MM/YYYY' }
                }"
                mode="range"
                v-model="calendarData"
              />

            </vs-col>
          </vs-row>
        </div>
      </vs-col>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="12">
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="searchBtn()" style="width:100%;" color="primary" type="filled">ค้นหา</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="4"
            class="px-2"
          >
            <vs-button @click="resetBtn()" style="width:100%;" color="warning" type="filled">reset</vs-button>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="4"
            class="px-2"
          >

          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table style="z-index:1;" pagination max-items="20" search :data="orders">
      <template style="z-index:1 !important;" slot="thead">

        <vs-th sort-key="updateDateTime"  style="width:10% !important;" >ลงรายการ</vs-th>
        <vs-th sort-key="orderNumber" style="width:15%;" >เลข Order</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName"  style="width:13%;" >ข้อมูลชื่อผู้เช่า</vs-th>
        <vs-th sort-key="rentalPriceTotal" style="width:13%;" >ยอดเช่าชุด</vs-th>
        <vs-th sort-key="bailTotal" style="width:15%;" >เงินประกัน</vs-th>
        <vs-th sort-key="bailReturningAmount" style="width:15%;" >คืนเงินประกัน</vs-th>
        <vs-th sort-key="returnDate" style="width:13%;" >วันคืนชุด</vs-th>
        <!-- <vs-th sort-key="orderStatus">สถานะ</vs-th> -->
        <!-- <vs-th sort-key="bailPaymentMethod" >วิธีชำระ</vs-th> -->
        <vs-th sort-key="bailReturningStatus" style="width:13%;" >สถานะคืนเงิน</vs-th>
        <vs-th>การจัดการ</vs-th>
      </template>

      <template style="z-index:1 !important;" slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td  style="width:10% !important; font-size: 0.8rem;" >
            <div> {{ formatDate(tr.updateDateTime) }}</div>
            <div>{{ tr.updateBy }}</div>
          </vs-td>
          <vs-td style="text-align:center;">
            <vs-button
              :href="getRedirectLink(tr.orderHeaderId)"
              target="blank"
              style="padding:0; font-size:11px; color: #2372CE; background-color: white !important; border: 0 white solid !important; font-weight:bold;"
            >{{ tr.orderNumber }}</vs-button>
            <br />
            {{ findBranchName(tr.branchId) }}
            <br />
            <!-- <div @click="changeToReturn($event, tr, indextr)" class="change-to-return">รอคืนชุด</div>
            <br />-->
          </vs-td>
          <!-- <vs-td>ข้อมูลสินค้า</vs-td> -->
          <vs-td style="max-width:150px; font-family: mitr;">
            {{ tr.customerName }}
            <br />
            {{ tr.customerAddress }}
            <br />
            {{ tr.customerPhone }}
          </vs-td>
          <!-- ค่าเช่า ใช้ Net price -->
          <vs-td v-if="tr.netPrice > 0" style="padding-left:20px;">
            {{
            formatPrice(
            tr.netPrice
            )
            }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า" >
             <div   :class="showPaymentClass(tr.rentalPaymentMethod)"    class="bailPayment px-2 py-1" >{{ showPayment(tr.rentalPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td>

           <vs-td v-else style="padding-left:20px;">
            {{
            realRentalPrice(
            tr.rentalPriceTotal,
            tr.rentalDiscount,
            tr.promotionDiscount
            )
            }}
            <vx-tooltip text="ช่องทางชำระค่าเช่า" >
             <div   :class="showPaymentClass(tr.rentalPaymentMethod)"    class="bailPayment px-2 py-1" >{{ showPayment(tr.rentalPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td>
          <vs-td style="padding-left:20px;">
            {{
            realBail(tr.bailTotal, tr.bailDiscount)
            }}
             <vx-tooltip text="ช่องทางชำระเงินประกัน" >
              <div   :class="showPaymentClass(tr.bailPaymentMethod)"     class="bailPayment px-2 py-1" >{{ showPayment(tr.bailPaymentMethod) }}</div>
            </vx-tooltip>
          </vs-td>

          <vs-td>
            {{ formatPrice(tr.bailReturningAmount) }}
            <vx-tooltip text="ช่องทางคืนเงินประกัน" >
              <div   :class="showPaymentClass(tr.returnMethod)"     class="bailPayment px-2 py-1" >{{ showPayment(tr.returnMethod) }}</div>
            </vx-tooltip>

          </vs-td >
          <vs-td style="font-size: 0.8rem;" >{{ formatDate(tr.returnDate) }}</vs-td>


          <vs-td>
            <span
              v-if="tr.bailReturningStatus === 'โอนแล้ว' || tr.bailReturningStatus === 'คืนแล้ว'"
              class="returningBail"
            >{{ tr.bailReturningStatus }}</span>
            <span v-else class="NotReturn">{{ tr.bailReturningStatus }}</span>
            <br />

            <span
              v-if="tr.bailReturningStatus === 'โอนแล้ว' || tr.bailReturningStatus === 'คืนแล้ว'"
              class="bailReturningDate"
            >{{ formatDate(tr.bailReturningDate) }}</span>

          </vs-td>
          <vs-td>
            <i
              v-if="$acl.check('admin') && tr.bailReturningStatus !== 'โอนแล้ว' && tr.bailReturningStatus !== 'คืนแล้ว'"
              @click="backStatus($event, indextr, tr)"
              style="color:#0069ff; font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >reply_all</i>
            <!-- *** 21Dec2021 * -->
            <i
              v-if="
                tr.remark1 === null ||
                  tr.remark1 === undefined ||
                  tr.remark1 === ''
              "
              @click="takeNote($event, tr)"
              style="font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >speaker_notes</i>
            <!-- *** 21Dec2021 * -->
            <i
              v-else
              @click="takeNote($event, tr)"
              style="color:#0069ff; font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >speaker_notes</i>
            <br />
            <i
              v-if="tr.bailReturningStatus === 'ยังไม่โอน' ||tr.bailReturningStatus === 'โอนแล้ว' "
              @click="changeToReturn($event, tr, indextr)"
              style="color:#0069ff; font-size:18px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons iconhover"
            >monetization_on</i>

          </vs-td>

          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import shapeFormat from "../../mixins/shapeFormat.js";
import CurrencyValue from "./Components/CurrencyValue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";

export default {
  mixins: [shapeFormat],
  data() {
    return {
      calendarData:{},
      dataImg: null,
      uploadIndex: -1,
      zoomPopupActive: false,
      zoomzoom: "",
      slip: [],
      file: "",
      uploadurl: "",
      orderHeaderId: "",
      backstatusIndex: -1,
      backstatusPopupActive: false,
      original: [],
      searchlist: [],
      permission: "",
      allBranch: [],
      allDetail: [],
      deh: [],
      currentBranch: null,
      currentUser: "guest",
      roles: [],
      takenote: "",
      takenoteIndex: 0,
      //*** 21Dec2021 */
      currentObject: {},
      currentBackStatus: {},
      theIndex: 0,
      branchCheckbox: [],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],
      branchSelect: [],
      // >>>>>>>> UPDATE INFO >>>>>>>>
      bailPaymentMethod: "ไม่ระบุ",
      bringBackBy: "",
      bringBackDate: "",
      state: " รอคืนชุด",
      orderNumber: "",

      // >>>>>>>> UPDATE INFO >>>>>>>>
      remark: "",
      deduction: 0,
      bankName: "",
      accountNumber: "",
      accountName: "",
      transferContact: "",
      deductionDate: null,
      dropzoneOptions: {},

      bailDiscount: 0,
      bailTotal: 0,
      images: [],

      // >>>>>>>> UPDATE INFO >>>>>>>>

      statusToReturn: { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
      statusToReturnOption: [
        { label: "รับชุดแล้ว", value: "รับชุดแล้ว" },
        { label: "ยกเลิก", value: "ยกเลิก" }
      ],
      notePopupActive: false,
      popupActive: false,
      searchDate: null,
      searchDateEnd: null,
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: { label: "เงินสด", value: "เงินสด" },
      payment_option: ["ไม่ระบุ", "เงินสด", "โอนเงิน"  , "Alipay/wechat" ,  "บัตรเครดิต", "ช่องทางอื่น"],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],
      orders: []
    };
  },
  async created() {
    // #@!
    var tk = localStorage.getItem("token");
    var tkpaser = JSON.parse(tk);
    this.$vs.loading();
    this.uploadurl =
      this.$store.state.apiURL + "/api/slip-upload/" + this.orderHeaderId;
    this.dropzoneOptions = {
      url: this.uploadurl,
      maxFilesize: 5, // MB
      headers: {
        Authorization: "bearer " + tkpaser
      }
    };

    this.currentUser = JSON.parse(localStorage.getItem("username"));
    this.roles = JSON.parse(localStorage.getItem("roles"));

    this.permission = this.checkPermission(this.roles);

    // EDIT BRANCH
    // this.allBranch = await this.loadBranch();
    // EDIT BRANCH
    // this.thisBranch = this.$store.state.auth.currentBranch;

    // var branch = null;
    var ord = [];

    this.allBranch = await this.loadBranch();

    // if (this.permission === "admin") {
    //   ord = await this.loadAllOrderShare();

    //   for (var m = 0; m < this.allBranch.length; m++) {
    //     this.branchSelect[m] = true;
    //   }
    // } else {
    //   // EDIT BRANCH

    //   //  *** BEGIN : 2020-11-28 : Search By branches
    //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
    //   //  *** END : 2020-11-28 : Search By branches
    // }
    //  *** BEGIN : 2020-12-04 : load just branch occupied
    ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
    //  *** END : 2020-12-04 : load just branch occupied

    // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    var theorder = await ord.filter(x => (x.orderStatus === "คืนชุดแล้ว" || x.orderStatus === "กำลังซักซ่อม" || x.orderStatus === "รอส่งซักซ่อม" || x.orderStatus === "เสร็จแล้ว") && x.orderNumber.startsWith("OR") );
    // console.log("theorder >>> ", theorder);

    var sorted = await this.descendingDateSortShare(theorder, "updateDateTime");

    this.orders = await sorted;
    this.original = await sorted;
    this.searchlist = await sorted;

    // this.branchCheckbox = await this.allBranch;

    //  *** BEGIN : 2020-11-28 : Search By branches
     this.branchCheckbox =  this.getMyBranches;
    //  *** END : 2020-11-28 : Search By branches

    this.images = await this.reloadImage();
    // console.log("All slip >> ", this.images);

    this.$vs.loading.close();
  },
  async mounted() {},

  methods: {
    showPaymentClass(pay) {
      // console.log('pay >>> ',pay);

      switch (pay) {

        case "เงินสด":
          return "label-btn-blue";

        case "โอนเงิน":
          return "label-btn-green";
        case "Alipay/wechat":
          return "label-btn-purple";
        case "บัตรเครดิต":
          return "label-btn-yellow";
        case "ช่องทางอื่น":
          return "label-btn-purple";
        default:
          return "label-btn-black";
      }
    },

    async updateCurrImg(input) {
      this.$vs.loading();

      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);

        const formData = new FormData();
        formData.append("file", input.target.files[0]);

        var submitRes = null;
        try {
          submitRes = await this.$http.post(
            this.$store.state.apiURL + "/api/slip-upload/" + this.orderHeaderId,
            formData,
            this.$store.state.auth.apiHeader
          );
        } catch (err) {
          submitRes = null;
        }

        //  console.log("submitRes >>>> ", submitRes);
        this.$vs.loading.close();

        if (submitRes !== null) {
          await this.reload();
           //  *** BEGIN : 2020-11-28 : Search By branches
          this.searchBtn();
          //  *** END : 2020-11-28 : Search By branches
          await this.reloadImage();
          this.dataImg = null;
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("file uploaded !!!");
          }, 1000);
        } else {
          this.noticeError("Something went wrong");
        }
      }
    },
    zoomimage(image) {
      this.zoomzoom = this.$store.state.slipStore + image;
      this.zoomPopupActive = true;
    },
    showSlip(name) {
      return this.$store.state.slipStore + name;
    },
    async reloadImage() {
      // RESET ALL IMAGES
      this.images = [];
      // LOAD รูปทั้งหมด
      var res = await this.$http.get(
        this.$store.state.apiURL + "/api/slip",
        this.$store.state.auth.apiHeader
      );
      var im = await res.data;
      this.slip = await im.filter(x => x.orderHeaderId === this.orderHeaderId);

      return res.data;
      // .then(response => {
      //   // console.log(response.status);
      //   if (response.status === 200) {
      //     {
      //       for (var i = 0; i < response.data.length; i++) {
      //         var p = response.data[i].fileName;

      //         var resp = {
      //           itemimage: this.$store.state.slipStore + p,
      //           imageid: response.data[i].itemImageId,
      //           fileName: p
      //         };
      //         this.images.push(resp);
      //       }

      //       // console.log("this.images >>> ", this.images);
      //     }
      //   } else {
      //     this.$vs.notify({
      //       title: "เกิดข้อผิดพลาดในการทำงาน",
      //       position: "top-center",
      //       text: "เกิดข้อผิดพลาดในการทำงาน",
      //       iconPack: "feather",
      //       icon: "icon-alert-circle",
      //       color: "danger"
      //     });
      //   }
      // });
    },
    onSelect() {
      const file = this.$refs.file.files[0];
      this.file = file;
    },

    async onSubmit() {
      this.$vs.loading({ type: "radius" });
      const formData = new FormData();
      formData.append("file", this.file);
      //  console.log("ON SUBMIT this.file >>>>>>>>>> ", this.file);

      var submitRes = null;
      try {
        submitRes = await this.$http.post(
          this.$store.state.apiURL + "/api/slip-upload/" + this.orderHeaderId,
          formData,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        submitRes = null;
      }

      if (submitRes !== null) {
        await this.reload();
        await this.reloadImage();
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("file uploaded !!!");
        }, 1000);
      } else {
        this.noticeError("Something went wrong");
      }
    },

    // TRY TO UPLOAD
    uploadComplete(file) {
      console.log(file);
      this.$refs.dropzone.removeAllFiles();
      setTimeout(() => {
        //your code to be executed after 1 second
        this.reloadImage();
      }, this.delayInMilliseconds);

      // location.reload(true);
    },
    async removeImage(imgid) {
      var res = null;
      try {
        res = await this.$http.delete(
          this.$store.state.apiURL + "/api/slip/" + imgid,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
      }
      if (res !== null) {
        this.reloadImage();
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("ลบสำเร็จ");
        }, 1000);
      } else {
        this.noticeError("ลบไม่สำเร็จ");
      }
    },
    // TRY TO UPLOAD
    removeAllFiles() {
      this.$refs.dropzone.removeAllFiles();
    },

    theLastBail() {
      var bd = isNaN(Number(this.bailDiscount)) ? 0 : Number(this.bailDiscount);

      var total = isNaN(Number(this.bailTotal)) ? 0 : Number(this.bailTotal);
      return total - bd;
    },
    getRedirectLink(orderId) {
      return "/backoffice/order-detail/" + orderId;
    },
    async detailOneOrder(orderId) {
      var list = await this.allDetail.filter(x => x.orderHeaderId === orderId);
      //  console.log("orderId >>> ", orderId, " list >>> ", list);
      return list;
    },
    theSearch(event, index) {
      this.activeb(event, index);
      this.searchBtn();
    },
    activeb(event, index) {
      //  *** BEGIN : 2020-11-28 : Search By branches
      var blist = this.getMyBranches;
      //  ***  END : 2020-11-28 : Search By branches
      this.branchCheckbox = [];

      if (event.target.className == "choose") {
        this.branchSelect[index] = false;
        event.target.className = "notChoose";
      } else {
        event.target.className = "choose";
        this.branchSelect[index] = true;
      }

      // ถ้า branchSelect ใส่ค่า
      for (var p = 0; p < blist.length; p++) {
        if (this.branchSelect[p] === true) {
          this.branchCheckbox.push(blist[p]);
        }
      }
    },
    searchBtn() {

     //  *** BEGIN : 2020-11-28 : Search By branches
     if(this.calendarData === null )
      {
        this.calendarData = {};
      }

     if(Object.entries(this.calendarData).length === 0 ){
       this.searchDate = null;
       this.searchDateEnd = null;
     }
      //  *** BEGIN : 2020-11-28 : Search By branches


      if (this.searchDate !== null && this.searchDateEnd !== null) {
        var searchDate = this.formatDate(this.searchDate);
        var searchDateEnd = this.formatDate(this.searchDateEnd);
        if (searchDate && searchDateEnd) {
          this.orders = this.searchByDate(
            this.orders,
            searchDate,
            searchDateEnd,
            "returnDate"
          );
        }
        // console.log("AFTER searchByDate >>> ", this.orders);
      }


    //   var list = [];
    //   this.orders = [];
    //   var searchDate = this.formatDate(this.searchDate);
    //   var searchDateEnd = this.formatDate(this.searchDateEnd);

    //   var startNull = false;
    //   var endNull = false;

    //   if (
    //     this.searchDate === "" ||
    //     this.searchDate === null ||
    //     this.searchDate === undefined
    //   ) {
    //     startNull = true;
    //   }

    //   if (
    //     this.searchDateEnd === "" ||
    //     this.searchDateEnd === null ||
    //     this.searchDateEnd === undefined
    //   ) {
    //     endNull = true;
    //   }



    //   // console.log(
    //   //   "@ CHECK POINT  sthis.branchCheckbox.length  >>> ",
    //   //   this.branchCheckbox.length
    //   // );

    //   var searchList = list;

    //   if (this.branchCheckbox.length >= 1) {
    //     for (var k = 0; k < searchList.length; k++) {
    //       for (var j = 0; j < this.branchCheckbox.length; j++) {
    //         if (searchList[k].branchId === this.branchCheckbox[j].branchId) {
    //           this.orders.push(searchList[k]);
    //         }
    //       }
    //     }
    //   }
    },
    async resetBtn() {
      this.searchDate = "";
      this.calendarData = {};
      await this.reload();
    },
    async reload() {
      // var branch = null;
      var ord = [];
      // this.permission = this.checkPermission(this.roles);
      // if (this.permission === "admin") {
      //   ord = await this.loadAllOrderShare();
      // } else {
      //    //  *** BEGIN : 2020-11-28 : Search By branches
      //   ord = await this.loadOrderByBranchShared(this.getMyBranches);
      //    //  *** END : 2020-11-28 : Search By branches
      // }
      //  *** BEGIN : 2020-12-04 : load just branch occupied
      ord = await this.loadOrderByBranchObjectShared(this.getCurrentBranch);
      //  *** END : 2020-12-04 : load just branch occupied
      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
      var theorder = await ord.filter(x => (x.orderStatus === "คืนชุดแล้ว" || x.orderStatus === "กำลังซักซ่อม" || x.orderStatus === "รอส่งซักซ่อม" || x.orderStatus === "เสร็จแล้ว") && x.orderNumber.startsWith("OR") );
      var sorted = await this.descendingDateSortShare(theorder, "updateDateTime");

      this.orders = await sorted;
      this.searchlist = await sorted;
      this.original = await sorted;

      // >>>>>>>>>>>>> INIT >>>>>>>>>>>>>>>>>>
    },
    changeToReturn: function(event, order, index) {
      event.stopPropagation();
      this.bailPaymentMethod = order.bailPaymentMethod;
      this.orderHeaderId = order.orderHeaderId;

      this.deductionDate = order.deductionDate;
      this.deduction = order.deduction;
      this.bringBackDate = order.bringBackDate;
      this.remark = order.remark1;
      this.bankName = order.bankName;
      this.accountNumber = order.accountNumber;
      this.accountName = order.accountName;
      this.transferContact = order.transferContact;
      this.bailTotal = order.bailTotal;
      this.bailDiscount = order.bailDiscount;
      // >>>>>  PROVIDE LINK FOR DROP ZONE

      this.slip = this.images.filter(
        x => x.orderHeaderId === this.orderHeaderId
      );

      this.theIndex = index;
      this.popupActive = true;
      this.reloadImage();
    },

    async update() {
      // >>>>>>>>>> Validate INFO
      if (
        this.bankName === null ||
        this.bankName === undefined ||
        this.bankName === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อธนาคาร");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        this.accountNumber === null ||
        this.accountNumber === undefined ||
        this.accountNumber === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่เลขบัญชี");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        this.accountName === null ||
        this.accountName === undefined ||
        this.accountName === ""
      ) {
        this.noticeWarning("ข้อมูลไม่ครบ", "ยังไม่ได่ใส่ชื่อธนาคาร");
        return false;
      }

      // >>>>>>>>>> Validate INFO
      if (
        this.transferContact === null ||
        this.transferContact === undefined ||
        this.transferContact === ""
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบ",
          "ยังไม่ได่ใส่เบอร์ติดต่อกลับหลังโอนเงิน"
        );
        return false;
      }

      this.$vs.loading({ type: "radius" });
      // SAVE HEADER

      var ord = {
        deduction: this.deduction,
        remark1: this.remark,
        bankName: this.bankName,
        accountNumber: this.accountNumber,
        accountName: this.accountName,
        transferContact: this.transferContact,

        bringBackBy: this.currentUser,

        updateBy: this.currentUser,

        orderStatus: "คืนชุดแล้ว"
      };

      // >>>> START TO SAVE HEADER
      var responseOrder;
      try {
        responseOrder = await this.$http.put(
          this.$store.state.apiURL +
            "/api/orderheader/bringback/" +
            this.orderHeaderId,
          ord,
          this.$store.state.auth.apiHeader
        );
        if (responseOrder.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
          }, 2000);
          this.popupActive = false;
        }
      } catch (error) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError(error);
        }, 2000);
      }

      // >>>> START TO SAVE HEADER
    },
    resetValue() {
      this.bailPaymentMethod = "ไม่ระบุ";
      this.state = "ไม่ระบุ";
      this.theIndex = -1;
      this.takenoteIndex = -1;
      this.remark = "";

      this.deduction = "";

      this.bankName = "";
      this.accountNumber = "";
      this.accountName = "";
      this.transferContact = "";
    },
    closePanel() {
      this.popupActive = false;
      this.resetValue();
    },
    changeStatus(state) {
      this.state = state;
    },
    changePayment(pay) {
      this.bailPaymentMethod = pay;
    },
    showPayment(pay) {
      if (pay === "เงินสด" || pay === "โอนเงิน" || pay === "Alipay/wechat"  || pay === "บัตรเครดิต" || pay === "ช่องทางอื่น") {
        return pay;
      } else {
        return "ยังไม่ชำระ";
      }
    },

    async findCurrentBranch(roles) {
      // >>>>>>> หา branch จาก  role ของ user >>>>>>>>>
      let branch_th = "1";
      var userrole = [];
      userrole = roles.find(x => x.roleName.includes("staff-"));
      if (userrole.length === 0 || userrole === undefined) {
        branch_th = "1";
      } else {
        var role_split = userrole.roleName.split("-");
        if (role_split[1] === null || role_split[1] === undefined) {
          branch_th = "1";
        }
        branch_th = role_split[1];
      }

      // console.log(" branch_th >>>> ", branch_th);

      var allBranches = await this.allBranch;

      var thisBranch = await allBranches.find(a => a.code === branch_th);

      return thisBranch;
    },

    findBranchName(id) {
      var b = this.allBranch.find(i => i.branchId === id);
      //  console.log("@ allBranch >>> ", this.allBranch);

      // return b.branchName;
      if (b === null || b === undefined) return "ไม่ระบุ";
      else return b.branchName;
    },

    async loadBranch() {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        this.noticeError(error);
        //  console.log("error", error);
      }

      return await response.data;
    },
    checkPermission(roles) {
      var pm = roles.filter(
        x =>
          x.roleName === "owner" ||
          x.roleName === "admin" ||
          x.roleName === "accountingadmin" ||
          x.roleName === "useradmin"
      );
      if (pm.length >= 1) {
        return "admin";
      } else {
        return "staff";
      }
    },
    confirmToChangeStatus(event) {
      event.stopPropagation();
      // this.orders[this.theIndex].orderStatus = "รับชุดแล้ว";
      // this.orders = this.orders.filter(x => x.orderStatus === "รอรับชุด");

      this.popupActive = false;
    },
    backStatus(event, index, item) {
      event.stopPropagation();
      this.currentBackStatus =  {};
      this.currentBackStatus =  item;
      this.orderNumber = item.orderNumber;
      this.backstatusPopupActive = true;
      this.backstatusIndex = index;
    },
    closeBackStatusNotePanel() {
      this.backstatusPopupActive = false;
      this.resetValue();
      this.orderNumber = "";
      this.currentBackStatus =  {};
    },
    async updateback() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.currentBackStatus.orderHeaderId;


      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/backtoreturn/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeBackStatusNotePanel();
          }, 300);
        }
      } catch (err) {
        this.$vs.loading.close();
        this.noticeError(err);
      }
    },
    //*** 21Dec2021 */
    takeNote(event, tr) {
       event.stopPropagation();
      // console.log("@@@@@ TAKE NOTE >>> ", this.orders[index]);
      //*** 21Dec2021 */
      this.currentObject = {};
      this.currentObject = Object.assign({},tr);
      this.remark = tr.remark1;
      this.notePopupActive = true;
    },
    async updatenote() {
      this.$vs.loading({ type: "radius" });
      var ordId = this.currentObject.orderHeaderId;

      var response;
      try {
        response = await this.$http.put(
          this.$store.state.apiURL + "/api/orderheader/takenote1/" + ordId,
          {
            note: this.remark,
            updateBy: this.currentUser
          },
          this.$store.state.auth.apiHeader
        );

        if (response.status === 200) {
          this.reload();
          setTimeout(() => {
            this.$vs.loading.close();
            this.noticeSuccess("ยืนยันข้อมูลเรียบร้อยแล้ว");
            this.popupActive = false;
            this.closeNotePanel();
          }, 300);
        }
      } catch (err) {
        this.noticeError(err);
      }
    },
    closeNotePanel() {
      this.notePopupActive = false;
      this.$vs.loading.close();
      this.resetValue();
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var ind = 0;

      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0 || this.selectStatus === 2) {
        // console.log("expected >>> 0 or 2");
        this.selectedList = [];
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = true;
          this.selectedList.push(this.orders[ind]);
        }
        this.selectStatus = 1;
      } else if (this.selectStatus === 1) {
        // console.log("expected >>> 1");
        for (ind = 0; ind < this.orders.length; ind++) {
          this.orders[ind].selected = false;
        }
        this.selectedList = [];
        this.selectStatus = 0;
      }
      this.fromSelectAll = true;
      // console.log(
      //   "SelectAll ==> " + this.selectAll + " STATUS  ===> " + this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    },
    addSelectedList(indextt, event) {
      event.stopPropagation();
      // console.log(this.orders[indextt].selected);

      if (!this.orders[indextt].selected) {
        this.orders[indextt].selected = true;
        this.selectedList.push(this.orders[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.orders[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.orders[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.orders[indextt].selected = !this.orders[indextt].selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.orders);
      // console.log(this.selectedList);
    }
  },

    computed: {
     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    }
     //  *** BEGIN : 2020-11-28 : Search By branches
  },

  components: {
    Prism,
    Datepicker,
    "v-select": vSelect,
    CurrencyValue,
    vueDropzone: vue2Dropzone
  },
  watch: {
    calendarData: {
      handler(value) {
        this.searchDate = this.formatDate(value.start);
        this.searchDateEnd = this.formatDate(value.end);
      },
      deep: true
    },

    async getCurrentBranch(){
      await this.reload();
      this.searchBtn();
      // console.log('curent brach changed !!! >>> ',value.branchName);

    },

    orders: {
      handler() {},
      deep: true
    },

    selectedList: function() {
      var ordersLength = this.orders.length;
      var selectedLength = this.selectedList.length;

      if (ordersLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN ordersLength == undefined");

        // console.log("SelectAll");
      }
      if (selectedLength == undefined) {
        this.selectStatus = 0;
        if (this.fromSelectAll === false) this.selectAll = false;
        // console.log("IN selectedLength == undefined)");
      }

      if (ordersLength == selectedLength && selectedLength > 0) {
        this.selectStatus = 1;
        if (this.fromSelectAll === false) this.selectAll = true;
        // console.log("IN ordersLength == selectedLength && selectedLength > 0");
        // console.log("SelectAll");
      } else {
        if (selectedLength == 0 || selectedLength == undefined) {
          this.selectStatus = 0;
          if (this.fromSelectAll === false) this.selectAll = false;
          // console.log("IN selectedLength == 0 || selectedLength == undefined");
        } else {
          this.selectStatus = 2;
          if (this.fromSelectAll === false) this.selectAll = true;
          // console.log("IN ELSE");
          // console.log("Select some");
        }
      }
      // console.log(this.orders);
      this.fromSelectAll = false;
      // console.log(
      //   "WACTCH selectedList >>> this.selectAll : " +
      //     this.selectAll +
      //     "  >>> this.selectStatus :  " +
      //     this.selectStatus
      // );

      // console.log(this.orders);
      // console.log(this.selectedList);
    }
    // branchCheckbox: function() {
    //   this.searchBtn();
    // }
  }
};
</script>

<style>

.vs__selected-options .vs__search{
  display: block !important;
}

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.changetoreturn {
  background: #f5a623;
  border: 1px solid #ad6c02;
  border-radius: 16px;
  font-family: 'mitr';
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}

/* v-select  */
.clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none !important;
}
.dropdown-menu {
  margin-top: 5 !important;
  background-color: white;
}
.selected-tag {
  width: 100% !important;
}
.vs__actions {
  display: none !important;
}

.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: 'mitr';
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.con-vs-popup .vs-popup {
  width: 750px !important;
}

.returningBail {
  font-family: 'mitr';
  font-size: 14px;
  color: #7ed321;
  letter-spacing: 0;
  text-align: center;
}

.NotReturn {
  font-family: 'mitr';
  font-size: 14px;
  color: #9b9b9b;
  letter-spacing: 0;
}

.bailReturningDate {
  font-family: 'mitr';
  font-size: 9px;
  color: #4a90e2;
  letter-spacing: 0;
  text-align: center;
}

.imgclass .aura-btn {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  visibility: hidden;
  position: absolute;
  width: 50px;
  height: 20px;
  border: #4c4c4c 1px solid;
  color: white;
  cursor: pointer;
  background-color: #e89999b5;
}

.imgclass .setmain-btn {
  text-align: center;
  margin-left: 20px;
  margin-top: 50px;
  visibility: hidden;
  position: absolute;
  width: 80px;
  height: 30px;
  border: #4c4c4c 1px solid;
  color: black;
  cursor: pointer;
  background-color: #ffffffb5;
}

.imgclass:hover .aura-btn {
  visibility: visible;
}

.delete-btn {
  visibility: visible;
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  visibility: hidden;
  position: absolute;
  width: 50px;
  height: 20px;
}

.delete-btn:hover {
  visibility: visible;
}

/* v-select  */
</style>
